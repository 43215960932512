.App {
  text-align: center;
}

.AppBackground {
  background-color: #000000;
  background-image: url('../public/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.z00tHeaderWrapper {
  display: flex;
  padding: 1em;
  justify-content: center;
  position: relative;
  z-index: 9999;
}

.z00tHeaderWrapper img {
  top: 0;
  transition: top 0.2s;
  position: relative;
}

.z00tHeaderWrapper a:hover img {
  top: -3px;
  cursor: pointer;
}

.z00tHeaderWrapper > * {
 display: block;
}

.z00tMachineWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 80vh;
  flex-direction: column;
  position: relative;
  z-index: 5;
}

.z00tMachine {
  width: 80%;
  height: auto;
  max-width: 307px;
  display: block;
  position: relative;
  z-index: 5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (min-width: 768px) {
  .z00tHeaderWrapper {
    justify-content: flex-start;
  }

  .z00tMachineWrapper {
    min-height: 85vh;
  }
}