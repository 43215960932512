.mintWrapper {
    margin-bottom: 10px;
    min-width: 300px;
}

.mintContainer {
    height: 57px;
    display: flex;
    justify-content: center;
}

.mintAmountETH, .mintLeft {
    font-family: 'Silom', Silom, serif;
    color: #6b8e73;
    font-size: 14px;
    margin-top: 8px;
    display: inline-block;
}

.mintLeft {
    font-size: 10px;
    margin-bottom: 8px;
}

.mintAmountETH span, a {
    font-family: 'Silom', Silom, serif;
    color: #6b8e73;
    font-size: 12px;
}

.mintAmountETH a {
    font-size: 14px;
}

.mintAmountETH a:hover {
    cursor: pointer;
    color: #85a68d;
}

.mintAmountContainer {
    font-family: 'Silom', Silom, serif;
    color: #fcfdf5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 101px;
    height: 100%;
    background: url(./assets/mint-bg-bg.png);
    background-size: cover;
    background-position: center;
}

.mintAmountNumber {
    font-size: 26px;
    padding-right: 8px;
}

.mintAmountLabel {
    font-size: 14px;
}

.mintModifierContainer {
    width: 32px;
    height: 100%;
    padding-left: 3px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mintModifierPlus, .mintModifierMinus {
    font-family: 'Silom', Silom, serif;
    background-size: cover;
    background-position: center;
    height: 27px;
    width: 32px;
    font-size: 24px;
    color: #fcfdf5;
    display: block;
}

.mintModifierPlus {
    background: url(./assets/mint-plus-bg.png);
}

.mintModifierMinus {
    background: url(./assets/mint-minus-bg.png);
}

.mintBtnContainer {
    background: url(./assets/mint-btn-bg.png);
    background-size: cover;
    background-position: center;
    width: 32px;
    height: 100%;
    margin-left: 3px;
}

.mintBtn {
    font-family: 'Silom', Silom, serif;
    color: #fcfdf5;
    margin-left: -2px;
    font-size: 12px;
    position: relative;
    height: 57px;
}

.mintBtn span {
    display: block;
    transform: rotate(-90deg);
}

.mintBtn:hover {
    cursor: pointer;
}

button {
 outline:0; border: 0; padding: 0; margin: 0; background: transparent;
}

.connectWalletWrapper .mintAmountContainer {
    width: 130px;
    margin-bottom: 10px;
    background-size: contain;
}

.connectWalletBtn {
    font-family: 'Silom', Silom, serif;
    color: #fcfdf5;
    font-size: 16px;
    display: block;
    padding: 8px 5px;
}